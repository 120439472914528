import React from 'react';
import styled from 'styled-components';
import {css} from 'emotion';
import {Spring, animated} from 'react-spring/renderprops';
import MomentUtils from '@date-io/moment';
import {DateTimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import {
    updateRideStartDate,
    updateStartTime,
    updateRideEndDate,
    updateEndTime,
} from '../../actions/rideActions';
import Moment from 'moment';
import { connect } from 'react-redux';


/* global tw */
const Wrapper = styled.div`
    ${tw`hidden`};
`

class NewDateTime extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            // minEndDate:
        }
    }

     handleClick = () => {
        if(this.refs.pickerRef){
            this.refs.pickerRef.open();  
          }
        }

    handleChange = (date) => {
        // if(this.props.isEndDate && Moment(date).isBefore(Moment(this.props.ride.startTime,'HH:mm:SS').format('HH:mm'+':00'),'hours')){
        //     alert('End Time cannot be less than ' + Moment(this.props.ride.startTime,'HH:mm:SS').add(2,'hours').format('hh:mm A'));
        //     this.props.dispatch(updateEndTime(Moment(this.props.ride.startTime,'HH:mm:ss').add(2,'hours').format('HH:mm'+':00'))); 
        //     return;   
        // }


        if(this.props.isEndDate && Moment(date).isBefore(Moment(this.props.ride.startDate + ' ' + this.props.ride.startTime,'DD-MM-YYYY HH:mm:SS').add(2,'hours'),'hours')){
            alert('End Time cannot be less than ' + Moment(this.props.ride.startTime,'HH:mm:SS').add(2,'hours').format('hh:mm A'));
            this.props.dispatch(updateEndTime(Moment(this.props.ride.startTime,'HH:mm:ss').add(2,'hours').format('HH:mm'+':00'))); 
            return;   
        }

        if(this.props.isStartDate && Moment(date).isBefore(Moment().add(Number(this.props.leadTime),'hours'),'h')){
            alert('Start Time cannot be less than ' + Moment().add(Number(this.props.leadTime),'hours').format('hh:mm A'));
            this.props.dispatch(updateStartTime(Moment().add(Number(this.props.leadTime),'hours').format('HH:mm'+':00'))); 
            return;   
        }

        if(this.props.isStartDate){
            this.props.dispatch(updateRideStartDate(Moment(date).format('DD-MM-YYYY')));
            this.props.dispatch(updateStartTime(Moment(date).format('HH:mm:SS')));  
            if(this.props.rentalType !== 2){
                this.props.dispatch(updateRideEndDate(Moment(date).format('DD-MM-YYYY')));
                this.props.dispatch(updateEndTime("23:30:00"));    
            }  
            else{
                this.props.dispatch(updateRideEndDate(Moment(date).add(1,'day').format('DD-MM-YYYY')));
                this.props.dispatch(updateEndTime(Moment(date).format('HH:mm'+':00')));   
                // alert(this.props.ride.startDate);     
            }
        }
        else{
            this.props.dispatch(updateRideEndDate(Moment(date).format('DD-MM-YYYY')));
            this.props.dispatch(updateEndTime(Moment(date).format('HH:mm'+':00')));    
        }

        if(this.props.hasOwnProperty('afterChange')){            
            this.props.afterChange();
        }
    }

    render(){
        return(
            <Wrapper>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker 
                // ampm={false}
                minutesStep={30}
                animateYearScrolling={true}
                ref="pickerRef" 
                onChange={(date)=>{this.handleChange(date);}}
                disablePast={true}
                minDate={this.props.isStartDate ? this.state.minEndDate : Moment(this.props.ride.startDate+' '+this.props.ride.startTime,'DD-MM-YYYY HH:mm:ss').format()}
                 />
            </MuiPickersUtilsProvider>
            </Wrapper>
        );
    }
}

const mapStateToProps = (state) => ({
    ride: state.ride,
    leadTime: state.ride.leadTime,
    rentalType: state.ride.rentalType
})


export default connect(mapStateToProps,null,null,{forwardRef : true})(NewDateTime);
