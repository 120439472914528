import React from 'react';
import {Spring, animated} from 'react-spring/renderprops';
import {css} from 'emotion';
import styled from 'styled-components';

import ChauffeurIcon from '../SvgComponents/chauffeur-icon.svg';
import BikeIcon from '../SvgComponents/bike-icon.svg';
import CarIcon from '../SvgComponents/car-icon.svg';

/*global tw*/

const RCBStyles = {
    wrapper: css`
        ${tw`lg:flex-1 flex flex-wrap lg:block w-full  my-2 px-2 mx-2 lg:px-10 py-1 lg:py-12 rounded bg-grey-light items-center relative overflow-hidden`};
        transition: all 0.25s ease-in-out;
        &:hover{
            cursor: pointer;
            background: white;
            box-shadow: 0 2px 24px -5px rgba(0,0,0,0.2);
        }
        
        @media(min-width: 992px){
            // width: auto;
        }
    `,
    wrapperDisabled: css`
        ${tw`flex lg:block w-full  my-2 px-2 lg:px-10 py-1 lg:py-12 rounded bg-grey-light  items-center disabled cursor-not-allowed opacity-60`};
        @media(min-width: 992px){
            width: 30%;
        }
    `,
    titleRow: css`
        ${tw`flex flex-wrap items-center`}
    `,
    icon: css`
        ${tw` h-8 lg:h-auto lg:w-auto mx-4 lg:-mx-4`};

    `,
    title: css`
        ${tw`uppercase font-bold lg:mt-4 lg:mb-1`};
        letter-spacing: -0.68px;
        background: -webkit-linear-gradient(#AA0BCA, #7D0EDE);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1rem;
        @media(min-width: 992px){
            font-size: 1.1rem;
        }
    `,
    description: css`
        ${tw`block font-sans -mt-1`}
        opacity: 0.4;
        font-family: ProximaNova-Regular;
        font-size: 16px;
        color: #000000;
        letter-spacing: -0.1px;
        line-height: 25.89px;
    `,
    activeIndicator: css`
        ${tw`absolute z-10`}
        left: 0;
        top: 30%;
        bottom: 0;
        height: 5rem;
        width: 1rem;
        background: #72C343;
    `,
}
const RentingChoice = styled.div`
  ${tw`relative overflow-hidden flex items-center flex-wrap bg-grey-light rounded-lg lg:mx-4 px-4 lg:px-8 py-8 my-4 cursor-pointer`};
  transition: all 0.2s ease-in-out;
  height: auto;
  width: 100%;
  @media (min-width: 992px){
    height: 16rem;
    width: calc(25% - 2rem);
  }
  img{
    ${tw`-mt-4 h-16 py-2 px-0 lg:px-2 lg:-ml-4`};
  }
  :hover{
    ${tw`bg-white shadow-md`};

  }
`

const ChoiceDescription = styled.div`
  ${tw``};
  h3{
    ${tw`m-0 text-xl lg:text-2xl tracking-tight`};
  }
  p{
    ${tw`text-sm lg:text-md opacity-60`};
  }
`

const RentingChoiceButton = (props) => (

    <RentingChoice onClick={props.onClick}>
        <img src={props.icon} alt={props.alt && props.alt}/>
        <ChoiceDescription>
            <h3>{props.title}</h3>
            <p>{props.description}</p>
        </ChoiceDescription>
        <Spring
            native
            from={{ o: 0, x: -200 }}
            to={{ o: props.active ? 1 : 0, x: props.active ? 0 : -200 }}>
            {({ x }) => (
                <animated.div style={{ transform: x.interpolate(x => `translate3d(${x}px, 0, 0)`) }}
                    className={RCBStyles.activeIndicator}>
                </animated.div>
            )}
        </Spring>
    </RentingChoice>

);

export default RentingChoiceButton;