import React from 'react';
import { css } from 'emotion';
// import styled, { keyframes } from 'react-emotion';
import styled from 'styled-components';
// import {Transition, animated} from 'react-spring/renderprops';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import SliderModal from './SliderModal';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {
    addStop,
    addStopAtIndex,
    editStop,
    pushStopAtIndex,
    updateDestLocation,
    updatePickupLocation,
    updateCityOfStop,
    updateRideDistance,
    updateRideDuration,
    addFirstStop,
    fetchLocationByLatLong,
    updateDestCity,
    updateRideStartDate,
    updateStartTime,
    updateRideEndDate,
    updateEndTime,
    updateTripType,
    removeLastStop
} from '../../actions/rideActions';
import 'react-dates/initialize';
import Moment from 'moment';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import { Transition, animated, Trail } from 'react-spring/renderprops';
import { navigate, Link } from 'gatsby';
import Row from '../Row';

/* global tw */

const styles = {

   
    autocompleteSuggestions: css`
        ${tw`list-reset w-full`};
        li{
            ${tw`py-2 px-1 my-1 text-grey-darker font-sans lg:text-lg hover:bg-grey-light`};

        }
    `,
    dateTimeScrollWrapper: css`
        ${tw`relative z-0 flex-1 lg:text-2xl py-2 px-2 overflow-y-scroll scrolling-touch`}; 
        height: 20rem;
        -webkit-overflow-scrolling: touch;
        -webkit-mask-image: -webkit-gradient(linear, left 90%,left bottom, 0%(rgba(0,0,0,1)), 25%(rgba(0,0,0,0)),75%(rgba(0,0,0,0)), 100%(rgba(0,0,0,1)))
        mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
    `,
     datePicker: css`
        ${tw`list-reset`};

        li{
            ${tw`my-2 py-1 lg:py-2 px-1 lg:px-2 cursor-pointer`};
            &:hover{
                ${tw`bg-grey-light`}
            }
        }
        li.active{
            ${tw`bg-grey-dark text-white`};
        }
    
    `,

}

const DatePickerList = styled.ul`
    ${tw`list-reset`};
`
const DateListItem = styled.li`
    ${tw`flex items-center relative my-2 py-1 lg:py-2 px-1 cursor-pointer text-grey-darker rounded-sm`};
    ${props => props.active && tw`bg-grey-light text-black font-black opacity-100 overflow-hidden px-6`}
    // transition: all .2s ease-in-out;
    &:hover{
        ${tw`bg-grey-light`}
    }
    ::before{
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        width: 1rem;
        // left: -25%;
        // top: -25%

        ${tw`pin-l pin-y bg-green opacity-0`};
        ${props => props.active && tw`opacity-100`};
    }
`



/* Converts 12 hour time format to 24 hour time format*/
function convertTime12to24(time12h) {
        
  
    let [time,modifier] = time12h.toString().split(' '); //Added toString as it was causing an error without it
    // alert(time+""+modifier);
    if(modifier === "AM"){
      let [hrs,mins] = time.split(":");
      if(hrs === '12'){
        hrs = '00';
      }
      return hrs + ':' + mins + ":00";
    }
    else if(modifier === "PM"){
      let [hrs,mins] = time.split(":");
      if(Number(hrs) < 12){
        hrs = Number(hrs) + 12;
      }
      return hrs + ':' + mins + ":00";
    }
}

const CancelToken = axios.CancelToken;
let cancel, i=0;
/* Timeline Labels in the caldenar*/
const timelineLabels = (desiredStartTime, interval, period) => {
    let periodsInADay = Moment.duration(1, 'day').as(period);
    periodsInADay = periodsInADay - ( (Number(desiredStartTime.split(':')[0]) * 60) + Number(desiredStartTime.split(':')[1]) + 30);
    const timeLabels = [];
    const startTimeMoment = Moment(desiredStartTime, 'hh:mm');
    for (let i = 0; i <= periodsInADay; i += interval) {
      startTimeMoment.add(i === 0 ? 0 : interval, period);
      timeLabels.push(startTimeMoment.format('hh:mm A'));
    }
  
    return timeLabels;
  };



class DateTime extends React.Component{
    constructor(props){
        super(props);
        this.state={
            text: "",
            // isOverlayActive: false,
            // isSliderModalActive: false,
            // isStartDate: false,
            // dateEditing: false,
            startDate: Moment(),
            endDate: Moment(),
            // editStartDate: false,
            // editingEndDate: false,
            // editingStartDate: false,
            startDates: [],
            startTimes: [],
            endDates: [],
            endTimes: [],
            buttonLang: 'Looks good!',
        }
    }
    componentWillMount(){
        
        // eslint-disable-next-line
        if(typeof analytics === `undefined`){
            return;
        }
        // eslint-disable-next-line
        // analytics.page('\webapp3.0',{category:'navigation',label:'Homepage'});
        // Below line - easier to call multiple times
        let ride = this.props.ride;
        let startDates = [], endDates=[];
        let today = Moment();
        /* Mysterious line of code below*/
        let minutes = 30 - (today.minute() % 30);
        // let leadTime = this.props.ride.leadTime;
        // let leadTime = 2;
        let leadTime = 4;
        // Initialize minimum Start Date and Start Date
        let minStartDate = Moment().add(leadTime, 'h').add(minutes, 'm').seconds('0');
        let startDate = Moment().add(leadTime, 'h').add(minutes, 'm').seconds('0');
        this.setState({ minStartDate });
        //Initialize start date if nothing is selected
        if(ride.startDate !== ''){
            this.setState({ 
                startDate: Moment(ride.startDate, "DD-MM-YYYY"),
                startTime: Moment(ride.startTime, "HH:mm:ss").format("hh:mm A"),
            });
        }
        // Set the state of startDate and init startTime based on the startDate, store in redux and convertTime 12 to 24
        // store in redux the startTime and startDate
        else{
            this.setState({ startDate, startTime: Moment(startDate).format("hh:mm A") }, function(){
                this.props.dispatch(updateStartTime(convertTime12to24(this.state.startTime)));
            });
            this.props.dispatch(updateRideStartDate(Moment(startDate).format("DD-MM-YYYY")));
        }
        
        //Show startDates in calendar from the minStartDate
        for(let i=0;i < 100; i++){
            startDates.push(Moment(minStartDate).add(i, 'd'));
        }
        //init startTime in minStartDate
        let startTime = Moment(minStartDate).format("HH:mm");
        //multiple startTimes generated using timelineLabels count
        let startTimes = timelineLabels(startTime,30,'m');
        //set startDates and startTimes variables into their states
        this.setState({ startDates, startTimes });
        let minEndDate;
        //if not selfDrive then add 2 hours to the end date from the startDate
        if(this.props.ride.rentalType !== 2){
            minEndDate = Moment(minStartDate).add(2,'h');
            //Played With1
            //2h
        }
        //else add 24 hours to the minimum endDate from the startDate
        else{
            minEndDate = Moment(minStartDate).add(3,'h');
            // minEndDate = Moment(minStartDate).add(3,'h');
        }
        //endDate is minimum endDate plus the time 11:30PM
        let endDate = Moment(minEndDate).hours(23).minutes(30);
        //Variable to state below
        this.setState({ minEndDate });
        if(ride.endDate !== ''){
            this.setState({ 
                endDate: Moment(ride.endDate, "DD-MM-YYYY"),
                endTime: Moment(ride.endTime, "HH:mm:ss").format("hh:mm A")
            });
            
        }

        else{
            this.setState({ endDate, endTime: Moment(endDate).format("hh:mm A") }, function(){
                this.props.dispatch(updateEndTime(convertTime12to24(this.state.endTime)));
            });
            this.props.dispatch(updateRideEndDate(Moment(endDate).format("DD-MM-YYYY")));
        }
        //minimum end date -1
        for(let i=0;i < 100; i++){
            endDates.push(Moment(minEndDate).add(i, 'd'));
            //i-1
        }
        // endTime = endDate
        let endTime = Moment(minEndDate).format("HH:mm");
        console.log("endTime",endTime);
        console.log("endDate",endDate);
        let endTimes = timelineLabels(endTime,30,'m');
        //variable to state
        this.setState({ endDates, endTimes });
    }


    componentDidUpdate(prevProps, prevState){
        //if LeadTime Changes
        if(this.props.ride.leadTime !== prevProps.ride.leadTime){
            let ride = this.props.ride;
        // this.useGeolocation();
            let startDates = [], endDates=[];
            let today = Moment();
            let minutes = 30 - (today.minute() % 30);
            // let leadTime = this.props.ride.leadTime;
            let leadTime = 4;
            let minStartDate = Moment().add(leadTime, 'h').add(minutes, 'm').seconds('0');
            console.log(leadTime);
            let startDate = Moment().add(leadTime, 'h').add(minutes, 'm').seconds('0');
            this.setState({ minStartDate });


            if(Moment(ride.startDate, 'DD-MM-YYYY').hours(ride.startTime.split(':')[0]).minutes(ride.startTime.split(':')[1]).diff(Moment(), 'h') < this.props.ride.leadTime){
                this.setState({ startDate, startTime: Moment(startDate).format("hh:mm A") }, function(){
                    this.props.dispatch(updateStartTime(convertTime12to24(this.state.startTime)));
                });
                this.props.dispatch(updateRideStartDate(Moment(startDate).format("DD-MM-YYYY")));
            }

            // if(ride.startDate === Moment().format("DD-MM-YYYY")){
            //     if(Moment(ride.startTime, "HH:mm:ss").diff(Moment(),'h') < this.props.ride.leadTime){
            //         this.setState({ startDate, startTime: Moment(startDate).format("hh:mm A") }, function(){
            //             this.props.dispatch(updateStartTime(convertTime12to24(this.state.startTime)));
            //         });
            //         this.props.dispatch(updateRideStartDate(Moment(startDate).format("DD-MM-YYYY")));
            //     }
            // }

            // this.setState({ startDate, startTime: Moment(startDate).format("hh:mm A") }, function(){
            //     this.props.dispatch(updateStartTime(convertTime12to24(this.state.startTime)));
            // });
            // this.props.dispatch(updateRideStartDate(Moment(startDate).format("DD-MM-YYYY")));
            
            


            for(let i=0;i < 100; i++){
                startDates.push(Moment(minStartDate).add(i, 'd'));
            }
            let startTime = Moment(minStartDate).format("HH:mm");
            let startTimes = timelineLabels(startTime,30,'m');
            this.setState({ startDates, startTimes });

            let minEndDate;
            let endDate;
            if(this.props.ride.rentalType !== 2){
                minEndDate = Moment(minStartDate).add(4,'h');
                //played
                endDate = Moment(ride.startDate, 'DD-MM-YYYY').hours(23).minutes(30);
            }
            else{
                minEndDate = Moment(minStartDate).add(1 ,'d');
                endDate = Moment(ride.startDate,'DD-MM-YYYY').add(1,'d');
                //.add(1,'d')
                // minEndDate = Moment(minStartDate);
                // endDate = Moment(ride.startDate,'DD-MM-YYYY');
            }
            this.setState({ minEndDate });

            this.setState({ endDate, endTime: this.props.rentalType === 2 ? Moment(Moment(startTime,"HH:mm").add(1,'d')).format("hh:mm A") : Moment(endDate).format("hh:mm A") }, ()=>{
                console.log(this.props.ride.startTime,this.state.endTime,startTime);
                this.props.dispatch(updateEndTime(convertTime12to24(this.state.endTime)));
                this.props.dispatch(updateRideEndDate(Moment(endDate).format("DD-MM-YYYY")));
            });
            console.log('endDate from update', endDate)
            
            for(let i=0;i < 100; i++){
                endDates.push(Moment(minEndDate).add(i, 'd'));
                //i-1
            }
            let endTime = Moment(minEndDate).format("HH:mm");
            let endTimes = timelineLabels(endTime,30,'m');
            this.setState({ endDates, endTimes });
        }
    }


    //When you change the startDate and accordingly want the endDate to change both in index and carsList
    handleStartDateChange(date){
    // eslint-disable-next-line
    analytics.track('Start Date ',{category:'clicks',label:date});
    
    let endDates = [];
    if(date.diff(Moment(this.state.minStartDate).hours(0).minutes(0),'d') === 0){
        let startTime = Moment(this.state.minStartDate).format("hh:mm A");
        this.setState({ startDate: Moment(this.state.minStartDate), startTimes: timelineLabels(Moment(this.state.minStartDate).format("HH:mm"),30,'m'), startTime: startTime }, function(){
            this.props.dispatch(updateRideStartDate(Moment(this.state.startDate).format("DD-MM-YYYY")));
            this.props.dispatch(updateStartTime(convertTime12to24(startTime)));
            if(this.props.ride.rentalType !== 2){
                //placeholder1
                this.setState({ minEndDate: Moment(this.state.startDate).add(2,'h') }, function(){
                    this.setState({ endDate: Moment(this.state.minEndDate).hours(23).minutes(30), endTime: "11:30 PM", endTimes: timelineLabels(Moment(this.state.minEndDate).add(1,'h').format("HH:mm"),30,'m') },function(){
                        this.props.dispatch(updateRideEndDate(Moment(this.state.endDate).format("DD-MM-YYYY")));
                        this.props.dispatch(updateEndTime(convertTime12to24("11:30 PM")));
                    });

                })
        }
        else{
            this.setState({ minEndDate: Moment(this.state.startDate).add(1,'h') }, function(){
                for(let i=0;i < 100; i++){
                    endDates.push(Moment(this.state.minEndDate).add(i, 'd'));
                }
                this.setState({ endDate: this.state.minEndDate,endDates, endTime: this.state.startTime, endTimes: timelineLabels(Moment(this.state.minEndDate).format("HH:mm"),30,'m') },function(){
                    this.props.dispatch(updateRideEndDate(Moment(this.state.endDate).format("DD-MM-YYYY")));
                    this.props.dispatch(updateEndTime(convertTime12to24(this.state.startTime)));
                    console.log(this.state.endDate);
                    console.log(this.state.startTime);
                });

            })
        }
        })
    }
    //mostly here's where the magic's gonna happen
    else{
        // let endDates = [];
        this.setState({startDate : Moment(date).hours(0).minutes(0)},function(){
        this.props.dispatch(updateRideStartDate(Moment(this.state.startDate).format("DD-MM-YYYY")));
        this.setState({ startTimes: timelineLabels("00:00",30,'m'), startTime: "12:00 AM"})
        this.props.dispatch(updateStartTime( "00:00:00"));
        if(this.props.ride.rentalType !== 2){
            //placeholder2
            this.setState({ minEndDate: Moment(this.state.startDate).add(2,'h') }, function(){
                for(let i=0;i < 100; i++){
                    endDates.push(Moment(this.state.minEndDate).add(i, 'd'));
                }
                this.setState({ endDate: Moment(this.state.minEndDate).hours(23).minutes(30), endDates , endTimes: timelineLabels(Moment(this.state.minEndDate.add(1,'h')).format("HH:mm"),30,'m'), endTime: "11:30 PM" },function(){
                    this.props.dispatch(updateRideEndDate(this.state.endDate.format("DD-MM-YYYY")))
                    this.props.dispatch(updateEndTime("23:30:00"));
                });
            })
        }
            else{
                this.setState({ minEndDate: Moment(this.state.startDate).add(1,'h') }, function(){
                    for(let i=0;i < 100; i++){
                        endDates.push(Moment(this.state.minEndDate).add(i, 'd'));
                    }
                    this.setState({ endDate: this.state.minEndDate.add(1,'d'), endDates , endTimes: timelineLabels(Moment(this.state.minEndDate.add(1,'h')).format("HH:mm"),30,'m'), endTime: this.state.startTime },function(){
                        console.log("minEndDate", this.state.minEndDate)
                        this.props.dispatch(updateRideEndDate(this.state.endDate.format("DD-MM-YYYY")))
                        this.props.dispatch(updateEndTime((convertTime12to24(this.state.startTime))));
                        console.log(this.state.endDate);
                        console.log(this.state.startTime);
                    });
                })
            }
      });
    }
    }

    handleStartTimeChange = (time) => {
        // eslint-disable-next-line
        analytics.track('Start Time',{category:'clicks',label:time});
        let endDates = [];
        this.setState({ startTime: time });
        this.props.dispatch(updateStartTime(convertTime12to24(time)));
        let convertedTime = convertTime12to24(time);
        let [hrs,mins,secs] = convertedTime.split(':');
        let self= this;
        //placeholder3
        this.setState({ minEndDate: this.props.rentalType === 2 ? Moment(this.state.startDate).add(1,'d') : Moment(this.state.startDate).hours(hrs).minutes(mins).add(24,'h') }, ()=>{
            console.log('helloooooo')            
            for(let i=0;i < 100; i++){
                endDates.push(Moment(this.state.minEndDate).add(i-1, 'd'));
            }
            this.setState({ endDate: Moment(this.state.minEndDate).hours(23).minutes(30),endDates,endTimes: timelineLabels(Moment(this.state.minEndDate.add(1,'h')).format("HH:mm"),30,'m'),endTime:this.props.rentalType === 2 ? this.state.startTime : Moment(this.state.minEndDate).hours(23).minutes(30).format("hh:mm A")},()=>{
                console.log('yooooooo');
                self.props.dispatch(updateRideEndDate(self.state.endDate.format("DD-MM-YYYY")));
                self.props.dispatch(updateEndTime(convertTime12to24(self.state.endTime)));
            });
        });
        console.log(this.state.endTime);
    }

    handleEndDateChange(date){
    
       let self = this
        // eslint-disable-next-line
        analytics.track('End Date',{category:'clicks',label:date});
        this.setState({ minEndDate: this.props.rentalType === 2 ? Moment(this.state.startDate).add(1,'d') : Moment(this.state.startDate).add(2,'h') })

        console.log("handleEndDateChange",Moment(self.state.minEndDate).format("HH:mm"))
        if(date.diff(Moment(this.state.minEndDate).hours(0).minutes(0),'d') === 0){
            this.setState({ endDate: date, endTimes: timelineLabels(Moment(this.state.minEndDate.add(1,'h')).format("HH:mm"),30,'m'),endTime: Moment(this.state.minEndDate).hours(23).minutes(30).format("hh:mm A") },function(){
                this.props.dispatch(updateRideEndDate(this.state.endDate.format("DD-MM-YYYY")));
                this.props.dispatch(updateEndTime(convertTime12to24(this.state.endTime)));
            });
        }
        else{
            this.setState({ endDate: date, endTimes: timelineLabels(Moment(this.state.minEndDate.add(1,'h')).format("HH:mm"),30,'m'), endTime: "11:30 PM"  })
            this.props.dispatch(updateEndTime("23:30:00"));
            this.props.dispatch(updateRideEndDate(date.format("DD-MM-YYYY")));
        }
    }

    handleEndTimeChange(time){
        // eslint-disable-next-line
        analytics.track('End Time',{category:'clicks',label:time});
        this.setState({ endTime: time }, function(){
            this.props.dispatch(updateEndTime(convertTime12to24(time)));
        });


    }
    

    componentDidMount(){
        let ride = this.props.ride;
    }

    render(){
        return(
            <SliderModal active={this.props.active} hasDone 
                    buttonLang={`${this.props.isStartDate ? this.state.startDate.format("DD MMM") : this.state.endDate.format("DD MMM")}, ${this.props.isStartDate ? this.state.startTime : this.state.endTime}`} 
                    onDone={this.props.onClick}>
                    <div className={css`${tw`w-full h-full relative`}`}>
                        <Row justifyBetween className={css`${tw`sticky lg:py-2 px-8 max-w-full`}; border-bottom: 2px solid rgba(0,0,0,0.1); `}>
                            <div className={css`${tw`w-full lg:w-auto`}`}>
                                <h3 className={css`${tw`w-full font-medium text-xl tracking-tight text-grey`}`}>
                                    When would you like to
                                    <span className={css`${tw`block text-xl lg:text-3xl text-purple font-bold`}`}>{this.props.isStartDate ? 'Start' : 'Return' }?</span>
                                </h3>
                            </div>
                        </Row>
                        <Row>
                            <div className={styles.dateTimeScrollWrapper}>
                                {/* <ul className={styles.datePicker}> */}
                                <DatePickerList>

                                    {this.props.isStartDate ? 
                                        this.state.startDates.map((date, i) =>
                                           
                                            <DateListItem 
                                                    active={this.state.startDate.format('ddd DD MMM') === date.format('ddd DD MMM')} 
                                                    onClick={() => this.handleStartDateChange(date)} key={i}>
                                                {date.format("ddd DD MMM")}
                                            </DateListItem>)
                                        : 
                                        this.state.endDates.map((date, i) =>
                                            <DateListItem 
                                                active={this.state.endDate.format('ddd DD MMM') === date.format('ddd DD MMM')} 
                                                onClick={() => this.handleEndDateChange(date)} key={i}>{
                                                    date.format("ddd DD MMM")}
                                            </DateListItem>
                                        )

                                    }
                                </DatePickerList>
                                {/* </ul> */}
                            </div>
                            <div className={styles.dateTimeScrollWrapper}>
                                <DatePickerList>
                                    {   this.props.isStartDate ? 
                                        
                                        this.state.startTimes.map((time, i) =>
                                            <DateListItem
                                                onClick={() => this.handleStartTimeChange(time)}
                                                active={this.state.startTime === time } 
                                                key={i}>
                                                {time}
                                            </DateListItem> ):
                                            // <li onClick={() => this.handleStartTimeChange(time)} key={i}>{time}
                                            // </li>):
                                        this.state.endTimes.map((time, i) =>
                                            <DateListItem
                                                onClick={() => this.handleEndTimeChange(time)}
                                                active={this.state.endTime === time}
                                                key={i}>
                                                 {time}
                                            </DateListItem>
                                        )

                                    }
                                </DatePickerList>
                            </div>
                        </Row>
                    </div>
            </SliderModal>
        );
    }
}

const mapStateToProps = (state) => ({
    ride: state.ride,
    leadTime: state.ride.leadTime,
    stops: state.ride.stops,
    isUserLoggedIn: state.user.isLoggedIn,
    rentalType: state.ride.rentalType
})


export default connect(mapStateToProps)(DateTime);