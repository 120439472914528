import React from 'react';
import { css } from 'emotion';
import Helmet from 'react-helmet';
import styled, { keyframes } from 'react-emotion';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import BlackOverlay from './BlackOverlay';
import SliderModal from './SliderModal';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import {
    addStop,
    addStopAtIndex,
    editStop,
    pushStopAtIndex,
    updateDestLocation,
    updatePickupLocation,
    updateCityOfStop,
    updateRideDistance,
    updateRideDuration,
    addFirstStop,
    fetchLocationByLatLong,
    updateDestCity,
    updateRideStartDate,
    updateStartTime,
    updateRideEndDate,
    updateEndTime,
    updateTripType,
    removeLastStop,
    updatePickupCity
} from '../../actions/rideActions';
import Geocode from "react-geocode";
import 'react-dates/initialize';
import Moment from 'moment';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { Transition, animated, interpolate } from 'react-spring/renderprops';
import { navigate, Link } from 'gatsby';
// import { applySchemaTransforms } from 'graphql-tools/dist/transforms/transforms';
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    DirectionsRenderer,
} = require("react-google-maps");


/* global tw */

const styles = {

    section: css`
        ${tw`relative w-full flex items-center justify-center w-screen mx-auto pin-t mt-0`};
    `,
    bgImage: css`
        ${tw`absolute z-back pin-x h-full w-full bg-trans`};
        top: -15vh;
        // background-image: linear-gradient(0deg, rgba(0,0,0,0.00) 10%, #000000 50%);
        // background-image: linear-gradient(6deg, rgba(38,21,195,0.55) 25%, rgba(200,10.9,215, 0.55) 74%);
        // border-radius: 0 0 50% 50%;

    `,
    row: css`
        ${tw`w-full flex flex-wrap justify-between items-center px-6 lg:px-0 -mx-2 lg:mx-auto`};   
        max-width: 82rem;
        @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            //Retina Specific
            max-width: 75rem;
        }
    `,
    title: css`
        ${tw``};
        margin: 0;
        font-size: 14vw;
        @media(min-width: 1000px){
            font-size: 5rem;
        }
    `,
    heroContent: css`
        ${tw`w-full flex flex-wrap max-w-row mx-auto`};
        margin-top: 33vh;
    `,
    mainHeading: css`
        ${tw`my-0 w-3/5 lg:w-auto`};
        color: #F0F2F5;
        font-size: 1.618rem;
        letter-spacing: 0;
        @media(min-width: 992px){
            font-size: 41.891223733px;
            letter-spacing: -1.618px;
        }
        span{
            font-size: 1rem;
            letter-spacing: 0;
            @media(min-width: 992px){
                font-size: calc(41.891223733px/1.618);
                letter-spacing: -0.809px;
            }
        }
    `,
    mainParagraph: css`
        ${tw`opacity-50 font-sans w-full mt-2 lg:w-2/3`}
        font-size: 0.8rem;
        @media(min-width: 992px){
            font-size: 1rem;
            line-height: 1.618rem;
        }
        color: #F0F2F5;
        letter-spacing: 0;
    `,
    bookingForm: css`
        ${tw`items-center flex flex-wrap bg-white mt-12 relative w-full overflow-hidden mx-4`};
        border-radius: 8.09px;
        box-shadow: 0 6px 24px rgba(0,0,0,0.2);
        height: max-content;
        // max-height: initial;
        @media (min-width: 992px){
            height: 38.195302843vh;
        }
    
    `,
    datePicker: css`
        ${tw`list-reset`};

        li{
            ${tw`my-4 py-1 lg:py-2 px-1 lg:px-4 cursor-pointer`};
            &:hover{
                ${tw`bg-grey-light`}
            }
        }
        li.active{
            ${tw`bg-grey-dark text-white`};
        }
    
    `,
    autocompleteSuggestions: css`
        ${tw`list-reset w-full`};
        li{
            ${tw`py-2 px-1 my-1 text-grey-darker font-sans lg:text-lg hover:bg-grey-light`};

        }
    `,
    dateTimeScrollWrapper: css`
        ${tw`w-gr1/3 lg:text-2xl mr-6 lg:px-2 py-2 overflow-y-scroll`}; 
        height:30vh;
        -webkit-mask-image: -webkit-gradient(linear, left 90%,left bottom, 0%(rgba(0,0,0,1)), 25%(rgba(0,0,0,0)),75%(rgba(0,0,0,0)), 100%(rgba(0,0,0,1)))
    `

}



const CancelToken = axios.CancelToken;
let cancel, i = 0;
// old key AIzaSyAEarG8tKd2cFXWtDkAfBOrymFUCfjuFok
// zyppys portal AIzaSyDmSzYU3JlM-AjP_XL9spDTO2A7iWfilUg
Geocode.setApiKey("AIzaSyDrm-YN3aa0Lj3VMz-hD0_IExL2BYZ06qA");


class PlacesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            isOverlayActive: false,
            isSliderModalActive: false,
            formEditing: false,
            isPlace: false,
            pickupAddress: null,
            destinationAddress: null,
            pickupLocation: "",
            destLocation: '',
            pickupCity: '',
            destCity: '',
            pickupPlaces: [],
            destPlaces: [],
            address: '',
            text: ''
           
        }
        this.textInput = React.createRef();
        this.myInput = React.createRef();
        // this.inputRef = React.createRef();
        // this.getDistanceBetweenStops = this.getDistanceBetweenStops.bind(this);
        this.updateDestLocation = this.updateDestLocation.bind(this);
        this.updatePickupLocation = this.updatePickupLocation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        // this.placesInput = null;
        // this.placesInput = React.createRef();
        this.attachInputRef = this.attachInputRef.bind(this);
        this.placesInput = null;
    }

    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = address => {
    /*    if(this.props.ride.rentalType === 3) {
            alert("2");
        } */
        this.props.onClick();
        
        let self = this;
        console.log("selection", address)
        geocodeByAddress(address)
    //  if(results[0].address_components.length>1 && (!results[0].address_components.formatted_address.includes("airport")||results[0].address_components.formatted_address.includes("Airport"))){
     
      



        .then(results => {
            ;
            console.log(results);
            // if(results[0].address_components.length>1){
            // geocodeByAddress(results[0].address_components[1].long_name)
            console.log("results", results);
            if (results[0].address_components.length > 1) {
                console.log("long name", results[0].address_components[1].long_name)
                let longName = "";
                results[0].address_components.map((ac) => {
                    if (ac.types.includes("administrative_area_level_1")) {
                        longName = ac.long_name
                    }
                })
                // if(longName !== "" || longName !== undefined || longName !== null){
                    
                // }
            // geocodeByAddress(results[0].address_components[1].long_name)
            geocodeByAddress(longName)
            .then(results1 => {
                console.log("result of 1", results1)
                if (this.props.isPickup && results1[0]) {
                    this.updatePickupLocation(results[0],results1[0]);
                    this.setState({ address: "" });
                //     if(this.props.ride.rentalType === 3) {
         
                //         let strmk = ' Airport';
                
                         
                //  if(!results[0].types.includes("Airport")&&!results[0].types.includes("airport")) {
                //         geocodeByAddress( address + strmk )
                //          .then(airportResults => {
                
                                      
                //              ;
                //              console.log("Airport Results",airportResults[0]);
                //              console.log("Mataar",airportResults);
                            
                //              if(airportResults[0].address_components.length>1 && airportResults[0].address_components[0].types.includes('airport',"Airport")){
                //                      this.updateDestLocation(airportResults[0]);
                //                      this.setState({ address: "" });                 
                //                  getLatLng(results[0])
                //          }
                        
                               
                
                               
                //             })
                //             .then(latLng => console.log('Success', latLng))
                //             .catch(error => console.error('Error', error));
                //         } }
                }
                else if(this.props.isPickup){
                    this.updatePickupLocation(results[0]);
                    this.setState({ address: "" });
                }
                else {
                    this.updateDestLocation(results[0]);
                    this.setState({ address: "" });
                }
                getLatLng(results[0])
            })
            .then(latLng => console.log('Success', latLng))
            .catch(error => console.error('Error', error));
        }
        // if (this.props.isPickup) {
        //     this.updatePickupLocation(results[0]);
        //     this.setState({ address: "" });
        // }
        // else {
        //     this.updateDestLocation(results[0]);
        //     this.setState({ address: "" });
        // }
        //         getLatLng(results[0])
                })
            .then(latLng => console.log('Success', latLng))
            .catch(error => console.error('Error', error));
        this.setState({ formEditing: false, });
    };
    
    componentWillMount() {
        
        // eslint-disable-next-line
        //this.input.focus();
        if (typeof analytics === `undefined`) {
            return;
        }
        // eslint-disable-next-line
        // analytics.page('\webapp3.0',{category:'navigation',label:'Homepage'});
        // let ride = this.props.ride;

    }

    updatePickupLocation = (details,altLoc) => {
        let city = '';
        let flag = 0;
        // eslint-disable-next-line
        analytics.track('Pickup Changed:', { category: 'clicks', label: details.formatted_address });
        this.setState({ pickupAddress: details.formatted_address });
        this.props.dispatch(updatePickupLocation({
            name: details.formatted_address,
            locLat: details.geometry.location.lat(),
            locLng: details.geometry.location.lng()
        }));
        //Finding city's name from details object
        let types = ['locality', 'administrative_area_level_2', 'administrative_area_level_1']
        if(details.address_components.length>1){
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < details.address_components.length; j++) {
                if (details.address_components[j].types[0] === types[i]) {
                    city = details.address_components[j].long_name;
                    flag = 1;
                    break;
                }
            }
            if (flag === 1) {
                break;
            }
        }
        }
        else{
            city = details.address_components[0].long_name;
                    flag = 1;
        }
        if (city === "Bangalore" || city === "Bengaluru Rural" || city === "Bengaluru Urban" || city === "Bangalore Rural" || city === "Bangalore Urban") {
            city = "Bengaluru";
        }
        if (city === "Secunderabad") {
            city = "Hyderabad";
        }
        
        this.setState({ pickupCity: city });
        // eslint-disable-next-line
        analytics.track('Pickup Changed:',{category:'clicks',label:city});
        if(altLoc){
            this.props.dispatch(fetchLocationByLatLong(city , altLoc.address_components[0].long_name));
            }
            else{
                let dummy="";
                this.props.dispatch(fetchLocationByLatLong(city,dummy));
            }
        this.props.dispatch(addFirstStop({
            city: city,
            name: details.formatted_address,
            latitude: details.geometry.location.lat(),
            longitude: details.geometry.location.lng()
        }));
        if (this.state.isRoundtrip) {
            this.props.dispatch(editStop(2, {
                city: city,
                name: details.formatted_address,
                latitude: details.geometry.location.lat(),
                longitude: details.geometry.location.lng(),
                cityLat: '',
                cityLng: ''
            }));
        }
        // this.getLatLngOfCity(city,'pickup');
    }


    updateDestLocation = (details) => {
        let destCity = '';
        let flag = 0, add = '';
        let formatted_address = '';
        let address = details.address_components;
        if (this.state.pickupAddress === null) {
            this.props.dispatch(pushStopAtIndex(1, {
                seqId: 2,
                name: details.formatted_address,
                latitude: details.geometry.location.lat(),
                longitude: details.geometry.location.lng()
            }));
        }

        else if (this.state.destinationAddress === null) {
            this.props.dispatch(addStop({
                name: details.formatted_address,
                latitude: details.geometry.location.lat(),
                longitude: details.geometry.location.lng()
            }));

            //adding first stop at the last if dest is entered after toggling to round trip
            if (this.props.isRoundtrip) {
                this.props.dispatch(addStop(this.props.ride.stops[0]));
            }
        }

        else {
            this.props.dispatch(editStop(1, {
                name: details.formatted_address,
                latitude: details.geometry.location.lat(),
                longitude: details.geometry.location.lng()
            }));
        }
        // eslint-disable-next-line
        analytics.track('Destination Changed:', { category: 'clicks', label: details.formatted_address });
        this.setState({ destinationAddress: details.formatted_address });

        this.props.dispatch(updateDestLocation({
            name: details.formatted_address,
            locLat: details.geometry.location.lat(),
            locLng: details.geometry.location.lng()
        }));

        // this.setState({ destinationAddress: details.formatted_address });

        //Finding city's name from details object
        let types = ['locality', 'administrative_area_level_2', 'administrative_area_level_1']
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < details.address_components.length; j++) {
                if (details.address_components[j].types[0] === types[i]) {
                    destCity = details.address_components[j].long_name;
                    flag = 1;
                    break;
                }
            }
            if (flag === 1) {
                break;
            }
        }
        if (destCity === "Bangalore" || destCity === "Bengaluru Rural" || destCity === "Bengaluru Urban" || destCity === "Bangalore Rural" || destCity === "Bangalore Urban") {
            destCity = "Bengaluru";
        }
        if (destCity === "Secunderabad") {
            destCity = "Hyderabad";
        }
        this.setState({ destinationCity: destCity });
        // eslint-disable-next-line
        analytics.track('Destination Changed:',{category:'clicks',label:destCity});
        this.getLatLngOfCity(destCity, 'dest');
    }


    getLatLngOfCity = (city, type) => {
        let self = this;
        Geocode.fromAddress(city)
            .then(json => {
                let location = json.results[0].geometry.location;
                
                if (type === 'pickup') {
                    self.props.dispatch(updateCityOfStop({
                        city: city,
                        cityLat: location.lat,
                        cityLng: location.lng
                    }, 0));
                }
                else {
                    self.props.dispatch(updateCityOfStop({
                        city: city,
                        cityLat: location.lat,
                        cityLng: location.lng
                    }, 1));
                    self.props.dispatch(updateDestCity({
                        city: city,
                        cityLat: location.lat,
                        cityLng: location.lng
                    }));
                }
            })
    }




    componentDidUpdate(prevProps, prevState) {
        // if(prevProps.stops !== this.props.stops && this.props.stops.length > 1){
        //     this.getDistanceBetweenStops();
        // }
        
        // let self = this;
        if (this.props.active !== prevProps.active) {
            this.setState({ address: "" });
            // this.input.focus();
            // alert('hi');
            // console.log(prevProps);

            // if(this.props.active){
            // setTimeout(function () {
            //     
            //     if (self.refs.myInput) ReactDOM.findDOMNode(self.refs.myInput).focus();
            // }, 500);
            // }
        }
        if(this.props.ride.rentalType !==2){
        if (prevState.isFocus !== this.state.isFocus) {
            window.setTimeout(function ()
            {
             document.getElementById('inputRef').focus();
            }, 0);
            //document.getElementById('inputRef').focus();
        }
    }
        // if ((this.props.isPickup || this.props.isDest) && !this.props.showHourlyPackageOptions) {
        //     setTimeout(function () {
        //         
        //         if (self.refs.myInput) ReactDOM.findDOMNode(self.refs.myInput).focus();
        //     }, 500);
        // }
    }

    componentDidMount() {
       
        // this.input.focus();
        // console.log('Input Node: ' + JSON.stringify(this.placesInput.current));
        let ride = this.props.ride;
        if (ride.pickup.loc !== "") {
            this.setState({ pickupAddress: this.props.ride.pickup.loc });
        }

        if (ride.dest.loc !== "") {
            this.setState({ destinationAddress: this.props.ride.dest.loc });
        }

        this.setState({isFocus:this.props.isFocus});

    }

    attachInputRef(input) {
        this.placesInput = input;
    }

    handleStaticCities = (data) =>{
        this.props.onClick();
        if(this.props.isPickup){
            this.props.dispatch(updatePickupCity({city:data.label,cityLat : data.value.latitude,cityLng:data.value.longitude, cityLeadTime : this.props.ride.leadTime.leadTime}));
        }
        else if(!this.props.isPickup){
            this.props.dispatch(updateDestCity({city:data.label,cityLat : data.value.latitude,cityLng:data.value.longitude}))
        }
    }

    render() {
        let options = {
            // types: ['(regions)'],
            componentRestrictions: { country: 'in' }
        };
        let selfFiltered = [];
        selfFiltered = this.props.ride.selfDriveCities.filter(c => c.name.toLowerCase().includes(this.state.text.toLowerCase()));
        let hourlyFiltered = [];
        hourlyFiltered = this.props.ride.hr4Cities.filter(c => c.name.toLowerCase().includes(this.state.text.toLowerCase()));

        return (
            <SliderModal placesModal onStart={()=>{this.setState({isFocus:!this.state.isFocus});}} active={this.props.active} onDone={this.props.onClick}>
                <div className={css`${tw`w-full flex flex-wrap px-6 py-4`}`}>
                    <div className={css`${tw`w-full`}`}>
                        <h3 className={css`${tw`font-medium text-grey tracking-tight`}`}>Where's your <span className={css`${tw`font-black text-2xl block text-purple`}`}>{this.props.isPickup ? 'Pickup' : 'Dropoff'} {this.props.ride.rentalType === 2 && this.props.ride.rentalType >=4 ? 'city' : 'location?'}</span></h3>
                    </div>
                    {this.props.ride.rentalType !== 2 && this.props.ride.rentalType <= 3 && <div className={css`${tw`w-full flex items-center`}`}>
                        <p className={css`${tw`text-xs text-grey`}`}>Powered by </p>
                        <img className={css`${tw`inline-flex self-center h-4 mx-2`}`} src={require('../../assets/img/google-logo.svg')} />
                    </div>}
                    {this.props.ride.rentalType !== 2 && this.props.ride.rentalType <= 3 ?<PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        searchOptions={options}
                        shouldFetchSuggestions={this.state.address.length > 1}
                        // ref="myInput">
                        inputProps={{
                            ref: input => {
                                console.log("REF INPUT SET");
                                this.searchInput = input;
                            }
                        }}>
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <>
                                <input
                                id="inputRef"
                                    {...getInputProps({
                                        autoFocus: true,
                                        placeholder: 'Search Places...',
                                        className: css`${tw`w-full px-4 text-xl lg:text-2xl font-bold py-4 border-none appearance-none bg-grey-light outline-none`}`,
                                    })}
                                />
                                <div className={css`${tw`w-full px-2 py-1 lg:py-8`}; max-height:12rem; overflow-y:scroll; -webkit-overflow-scrolling: touch;`}>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? css`${tw`my-4 lg:text-xl lg:py-1 text-grey-dark font-poppins font-medium tracking-tight`}`
                                            : css`${tw`my-4 lg:text-xl lg:py-1 bg-grey-light text-grey-darker font-poppins font-medium tracking-tight`}`;
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </PlacesAutocomplete>: 
                   (

                    (this.props.ride.rentalType ===2 ? <span className={css`${tw`w-full pr-6`}`}>
                    <input
                        id="inputRef"
                        autoFocus={true}
                        placeholder='Search Cities...'
                        className={css`${tw`w-full flex px-4 text-xl lg:text-2xl font-bold py-4 border-none appearance-none bg-grey-light outline-none`}`}
                        value={this.state.text}
                        // onChange = {(e) => {this.setState({ text: e.target.value})}}
                        onChange={(e) => this.setState({ text: e.target.value })}
                    />
                    {this.state.text!==''&&<div className={css`${tw`overflow-y-scroll py-2 w-full px-2`}; max-height:20rem; overflow-y:scroll; -webkit-overflow-scrolling: touch;`}> {selfFiltered.map((city, i) =>
                        (<p key={i} className={css`${tw`my-4 lg:text-xl lg:py-1 text-grey-darker font-poppins font-medium tracking-tight hover:bg-grey-light cursor-pointer`}`} onClick={() => { this.handleStaticCities(city) }}>{city.name}</p>)
                    )}</div>}
                    {this.state.text !== '' && selfFiltered.length <= 0 && 
                    <div>
                        <p>This city is not available at the moment</p>
                        </div>}
                </span>
                    :
                    (this.props.ride.rentalType ===3 && this.props.isPickup ? <div className={css`${tw`overflow-y-scroll py-2 w-full px-2`}; max-height:20rem; overflow-y:scroll; -webkit-overflow-scrolling: touch;`}> {this.props.ride.airportPickupCities.map((city) =>
                    (<p className={css`${tw`my-4 lg:text-xl lg:py-1 text-grey-darker font-poppins font-medium tracking-tight hover:bg-grey-light cursor-pointer`}`} onClick={()=>{this.handleStaticCities(city)}}>{city.name}</p>)
                    )}</div>
                    :
                    (this.props.ride.rentalType ===3 && !this.props.isPickup ? <div className={css`${tw`overflow-y-scroll py-2 w-full px-2`}; max-height:20rem; overflow-y:scroll; -webkit-overflow-scrolling: touch;`}> {this.props.ride.airportDropCities.map((city) =>
                    (<p className={css`${tw`my-4 lg:text-xl lg:py-1 text-grey-darker font-poppins font-medium tracking-tight hover:bg-grey-light cursor-pointer`}`} onClick={()=>{this.handleStaticCities(city)}}>{city.name}</p>)
                    )}</div>
                    :
                   this.props.ride.rentalType >3 && <span className={css`${tw`w-full pr-6`}`}>
                    <input
                        id="inputRef"
                        autoFocus={true}
                        placeholder='Search Cities...'
                        className={css`${tw`w-full flex px-4 text-xl lg:text-2xl font-bold py-4 border-none appearance-none bg-grey-light outline-none`}`}
                        value={this.state.text}
                        // onChange = {(e) => {this.setState({ text: e.target.value})}}
                        onChange={(e) => this.setState({ text: e.target.value })}
                    />
                    {this.state.text!==''&&<div className={css`${tw`overflow-y-scroll py-2 w-full px-2`}; max-height:20rem; overflow-y:scroll; -webkit-overflow-scrolling: touch;`}> {hourlyFiltered.map((city, i) =>
                        (<p key={i} className={css`${tw`my-4 lg:text-xl lg:py-1 text-grey-darker font-poppins font-medium tracking-tight hover:bg-grey-light cursor-pointer`}`} onClick={() => { this.handleStaticCities(city) }}>{city.name}</p>)
                    )}</div>}
                    {this.state.text !== '' && hourlyFiltered.length <= 0 && 
                    <div>
                        <p>This city is not available at the moment</p>
                        </div>}
                </span>
                    )
                    )
                    )
                    )
                    }

                    {/* <div onClick={this.props.onClick} className={css`${tw`appearance-none border-none shadow-none w-full flex items-center text-xl justify-center px-4 py-2 lg:py-6 mt-4 bg-black text-white text-center cursor-pointer hover:bg-grey-dark rounded-sm`}`}>
                                Done
                            </div> */}
                </div>
            </SliderModal>
        );
    }
}

const mapStateToProps = (state) => ({
    ride: state.ride,
    stops: state.ride.stops,
    isUserLoggedIn: state.user.isLoggedIn,
})


export default connect(mapStateToProps)(PlacesModal);
